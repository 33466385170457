<template>
  <main id="app" :class="$route.name">
    <!-- <template v-if="CheckLoadedModels()">
      <Loader />
    </template> -->

    <!-- Logo und HeaderCard -->

    <!--<Loader />-->

    <InlineLoader v-if="$route.query.mustLoadStack" />


    <Header />

    <div class="UI" :class="$route.name">
      <vue-page-transition
        class="ui-transition"
        name="fade"
        :class="$route.name"
      >
        <router-view class="ui-router"></router-view>
      </vue-page-transition>
    </div>

    <XRScene />

    <MainNavigation />
    <Footer v-if="$route.name != 'Home'"/>
    
    <Cookies v-if="this.$store.state.cookies && !mainConfig.development" />

    <div id="hidden-utils">
      <POIIcon />
    </div>
  </main>
</template>

<script>
import Header from "./Components/Header.vue";
import Loader from "./Components/Loader.vue";
import InlineLoader from "./Components/InlineLoader.vue";
import RouterLink from "./Components/RouterLinkComponent.vue";

import CanvasController from "./Components/CanvasController";

import Gallery360 from "./Scene/Gallery360";
import SolarwendeLogo from "./SVG/solarwende_logo.svg";

import { store } from "./store/store";
import XRScene from "./Scene/XRScene.vue";
import MainNavigation from "./Components/MainNavigation.vue";

import POIIcon from "./SVG/POI/POI_svg";
import mainConfig from "../main.config";
import FallbackCheckMixin from "./Mixins/FallbackCheckMixin";
import Cookies from "./Components/Cookies.vue";

import MainScene from './Scene/Mainscene';
import PartialLoader from './Components/PartialLoader.vue';

import Footer from './UI_Sections/Footer/Footer.vue';


// import BatteryIcon from './SVG/Dashboard/Battery';
// import VerbraucherIcon from './SVG/Dashboard/Verbraucher';
// import StromzaehlerIcon from './SVG/Dashboard/Stromzaehler';
// import PVIcon from './SVG/Dashboard/PV';
// import StromnetzIcon from './SVG/Dashboard/Stromnetz';
// import WechselrichterIcon from './SVG/Dashboard/Wechselrichter';

export default {
  name: "app",
  store,
  mixins: [FallbackCheckMixin],
  components: {
    Header,
    Loader,
    InlineLoader,
    RouterLink,
    SolarwendeLogo,
    XRScene,
    MainNavigation,
    POIIcon,
    Cookies,
    PartialLoader,
    Footer,
    // BatteryIcon,
    // VerbraucherIcon,
    // StromzaehlerIcon,
    // PVIcon,
    // StromnetzIcon,
    // WechselrichterIcon
  },
  data() {
    return {
      AudioController: null,
      canvasController: null,
      value: 0.5,
      mainConfig : mainConfig
    };
  },
  computed: {
    GetDisplay: function () {
      return {
        display: this.$route.name === "Home" ? "none" : "block",
      };
    },
  },
  mounted() {

    if (!mainConfig.logMessages) {
      // console.log(
      //   "%c ✅ > console.log auskommentiert => App.js",
      //   "background:#eee;font-weight:bold; padding:1rem;"
      // );
      console.log = ({ ...opt }) => {};
    }
    
    this.$store.dispatch("world/LoadRoom", "TinyCity");



    window.addEventListener("mousedown", this.EnableSounds);
    window.addEventListener("touchdown", this.EnableSounds);
    /*console.log(
      "%c App mounted ",
      "background:#558b2f; color:#fff; font-weight:700;"
    );*/
  },
  methods: {
    CheckLoadedModels() {
      //FEATURE Möglichkeit für direktes Öffnen einer Unterseite
      return this.$store.state.world.isLoading;
    },
    CheckLoader() {
      //console.log(this.$store.state.world.mainScene != null);
      return this.$store.state.world.mainScene != null;
    },
    EnableSounds(){

      console.log("enableSounds");
      if(this.$store.state.audio.controller != null && !this.$store.state.audio.controller.enabled){
        this.$store.state.audio.controller.EnableSounds();
      }
    }
  },
};
</script>

<style lang="scss">
@import "./styles/imports.scss";
@import "./styles/range-slider.scss";

.scene-renderer{
  width:100%!important;
  height:100%!important;
}
.partialloader{
  position: fixed;
  top:0;
  left:0;
  width:100%;

  background:tomato;
  z-index: 999;
}

.slider {
  position: absolute;
  left: calc(var(--left) - 18px);
  top: calc(var(--top) - 19px);
  width: calc(var(--width) + 38px);

  input[type="range"] {
    width: 100%;
    //opacity: 0
  }
}

.ui_overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.UI,
#scene,
.ui-transition {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  //background: #F3F3F3;
}

.UI.Configurator,
.UI .ui-router,
.ui-transition.Configurator {
  overflow: initial;
}

.ui-router {
  overflow: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar,
  &::-moz-scrollbar {
    display: none;
  }
}

.UI {
  //overflow-y: auto;
  z-index: 1;
  pointer-events: none;

  &.Gallery {
    pointer-events: all;
  }
}

div#canvas-holder {
  width: 800px;
  height: 460px;
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;
  z-index: 0;
  //background: rgba(0,0,0,.2);
}

canvas {
  width: 100%;
  height: 100%;
  display: block;
}

#hidden-utils {
  display: none;
}


.Home #scene{
  display:none;
}
</style>
