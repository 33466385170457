const mainConfig = {
  CMS_BASE_URL : "https://invisiblespace.xyz" ,//"https://invisibles.xyz",
  PAGE_BASE_URL : "https://solarzentrum-interaktiv.de", //"https://development.invisibleroom.com",//
  development : false,
  vr_development : false,
  vrClipping : false,
  clippingHelperVisible : false,
  transformControls : false,
  changeControlsToStatic : true,
  logMessages : false,
  NoTransition : false,
}

export default mainConfig;